import { useAtomValue } from 'jotai'
import dynamic from 'next/dynamic'
import { PropsWithChildren, createContext, useMemo, useState } from 'react'

import { globalState } from '@/atoms'

export interface Options {
  offsetTop?: 'FULL' | 'WITHOUT_HEADER' | (string & {})
}

const GlobalLoadingSpinner = dynamic(
  () =>
    import('@/Providers/global-loading-spinner-provider/GlobalLoadingSpinner').then(
      (component) => component.default
    ),
  {
    ssr: false,
  }
)

export function GlobalLoadingSpinnerProvider({ children }: PropsWithChildren<Options>) {
  const globalStateValue = useAtomValue(globalState)
  const [options, setOptions] = useState(INITIAL_OPTIONS)
  const value = useMemo(() => ({ options, setOptions }), [options])

  return (
    <GlobalLoadingSpinnerContext.Provider value={value}>
      {children}
      {!globalStateValue.isSkeleton && <GlobalLoadingSpinner />}
    </GlobalLoadingSpinnerContext.Provider>
  )
}

const INITIAL_OPTIONS: Options = { offsetTop: 'FULL' }

export const GlobalLoadingSpinnerContext = createContext({
  options: INITIAL_OPTIONS,
  setOptions: (_: Options) => {},
})
