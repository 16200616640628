import { Dictionary, getSearchParams } from '@tmap-web-lib/utils'
import isEmpty from 'lodash-es/isEmpty'
import { PropsWithChildren, createContext, useMemo } from 'react'
import Url from 'url-parse'

import { pageIdInfos } from '@/Providers'

import { PageId, PageIdContext, PageIdExtra, PageIdSearchParams } from '../types'

type Props = PropsWithChildren<{}>

const Context = createContext({} as PageIdContext)

function PageIdContextProvider(props: Props) {
  const { children } = props
  const pageIdContext = useMemo(() => createPageIdContext(), [])

  return (
    <Context.Provider value={pageIdContext}>
      <>{children}</>
    </Context.Provider>
  )
}

// 반환 타입을 명시적으로 지정
function createPageIdContext(): any {
  if (typeof window === 'undefined') {
    // 서버 사이드에서는 빈 객체를 반환
    return {
      pageIdInfo: null,
      pageId: undefined,
      extra: undefined,
      extraObject: {},
      searchParams: {},
      searchParamsString: '',
    }
  }

  const urlObject = new Url(window.location.href)
  const searchParams = getSearchParams<PageIdSearchParams>()
  const { pageid, extra } = searchParams
  const defaultExtra = isEmpty(extra)
    ? JSON.stringify({
        logbox: {
          type: 'tab',
          origin: 'tab',
        },
      })
    : extra && decodeURIComponent(extra)

  let extraObject: PageIdExtra & Dictionary<string | number | boolean | undefined>
  try {
    extraObject = JSON.parse(defaultExtra as string)
  } catch {
    extraObject = {}
  }
  const queryString = urlObject.query
  return {
    pageIdInfo: pageIdInfos[pageid as PageId] || null,
    pageId: pageid,
    extra,
    extraObject: extraObject,
    searchParams,
    searchParamsString: queryString.replace(/^\?/, ''),
  }
}

export { Context, createPageIdContext }
export default PageIdContextProvider
