import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage'

export const STORAGE_ACCESS_KEY = 'STORAGE-ACCESS-KEY'
export const STORAGE_MOCK_ENABLED = 'STORAGE_MOCK-ENABLED'
export const STORAGE_TOOLTIP_CLICKED = 'STORAGE-TOOLTIP-CLICKED'
export const STORAGE_SCHEME_URL = 'STORAGE-SCHEME-URL'

export type GlobalState = {
  accessKey: string
  carrierName: string
  deviceId: string
  euk: string
  sessionId: string
  accessKeyExpired: boolean
  isSkeleton: boolean
  isMockEnabled: boolean
  remoteConfigServiceList: string
  schemeURL: string
  bizPlaceBanner: string
  isTooltipClicked: boolean
}

export const globalState = atom<GlobalState>({
  accessKey: '',
  carrierName: '',
  deviceId: '',
  euk: '',
  sessionId: '',
  accessKeyExpired: false,
  isSkeleton: false,
  isMockEnabled: false,
  remoteConfigServiceList: '',
  schemeURL: '',
  bizPlaceBanner: '',
  isTooltipClicked: false,
})

export const accessKeyAtom = atom((get) => get(globalState).accessKey)
export const deviceInfoAtom = atom((get) => ({
  deviceId: get(globalState).deviceId,
  carrierName: get(globalState).carrierName,
}))

export const eukAtom = atom((get) => get(globalState).euk)
export const sessionIdAtom = atom((get) => get(globalState).sessionId)
export const accessKeyExpiredAtom = atom((get) => get(globalState).accessKeyExpired)
export const isSkeletonAtom = atom((get) => get(globalState).isSkeleton)
export const isMockEnabledAtom = atom((get) => get(globalState).isMockEnabled)
export const remoteConfigServiceListAtom = atom((get) => get(globalState).remoteConfigServiceList)
export const bizPlaceBannerAtom = atom((get) => get(globalState).bizPlaceBanner)
export const schemeURLAtom = atom((get) => get(globalState).schemeURL)
export const isTooltipClickedAtom = atom((get) => get(globalState).isTooltipClicked)

const customStorage: SyncStorage<string | object> = {
  getItem: (key: string) => {
    const value = localStorage.getItem(key)
    try {
      return value ? JSON.parse(value) : '' // `undefined` 대신 빈 문자열 반환
    } catch {
      return '' // JSON 파싱 실패 시 기본 반환 값
    }
  },
  setItem: (key: string, newValue: string | object) => {
    const value = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue
    localStorage.setItem(key, value)
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key)
  },
}

const getInitialValue = (key: string, defaultValue: string | object | boolean) => {
  if (typeof window === 'undefined') {
    return defaultValue
  }

  const result = localStorage.getItem(key)

  if (!result) {
    return defaultValue
  }

  return typeof defaultValue === 'string' ? result : JSON.parse(result)
}

export const storageAccessKeyAtom = atomWithStorage<string | object>(
  STORAGE_ACCESS_KEY,
  getInitialValue(STORAGE_ACCESS_KEY, ''),
  customStorage
)

export const storageTooltipClicked = atomWithStorage(
  STORAGE_TOOLTIP_CLICKED,
  getInitialValue(STORAGE_TOOLTIP_CLICKED, false)
)

export const storageMockEnabled = atomWithStorage(
  STORAGE_MOCK_ENABLED,
  getInitialValue(STORAGE_MOCK_ENABLED, false)
)
export const storageSchemeUrl = atomWithStorage(
  STORAGE_SCHEME_URL,
  getInitialValue(STORAGE_SCHEME_URL, false)
)
